<template>
  <SvgIcon name="events-arrow" :width="props.width" :height="props.height" style="transform: rotate(90deg)" />
</template>
<script lang="ts" setup>
const props = defineProps({
  width: {
    type: Number,
    default: 20
  },
  height: {
    type: Number,
    default: 20
  }
})
</script>
<style lang="scss" scoped></style>
